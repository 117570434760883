import axios from "axios";
import api from "../utils/api";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

class ModuleManagement {
    sendMessage = async (params) => {
        params = {
        ...params
        }
        return await api.postAxios(
        "/email",
        params,
        { cancelToken: source.token }
        );
    };
}

export default new ModuleManagement();
