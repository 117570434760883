import React, { useEffect , useState, useRef, useContext  } from 'react'
import Slider from "react-slick";
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import img_1 from '../../assets/images/fnb/food_1.png';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import { SectionRefsContext } from '../../contex/SectionRefsProvider';
import SliderExperimental from '../../components/SliderExperimental/SliderExperimental';

function OurClient() {
    const { section5Ref } = useContext(SectionRefsContext);
    const serviceData = [
        {
            type:`text`,
            imageLink:``,
            description:`Personalized pre-counseling and medical exam.`,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/img-20180306-wa0029-4.jpg`,
            description:``,
        },
        {
            type:`text`,
            imageLink:``,
            description:`Blood tests, vaccine history and profile completion (i.e. dietary habits, physical activity).`,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/service-c.jpg`,
            description:``,
        },
        {
            type:`text`,
            imageLink:``,
            description:`Performance analysis of the heart, and cardiopulmonary system.`,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/service-a-1.jpg`,
            description:``,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/service-d.jpg`,
            description:``,
        },
        {
            type:`text`,
            imageLink:``,
            description:`Standard check-ups and tailored examinations depending on patient’s needs.`,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/img-20180306-wa0024-1.jpg`,
            description:``,
        },
        {
            type:`text`,
            imageLink:``,
            description:`Specialized imaging technologies, as necessary.`,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/002.jpg`,
            description:``,
        },
        {
            type:`text`,
            imageLink:``,
            description:`Assessment and in-depth explanation of results by our medical experts.`,
        },
    ]
    return (
        <Box ref={section5Ref} sx={{backgroundColor:'#ffffff'}} py={5}>
            <Box sx={{backgroundColor:'#f5f5f5'}} py={5}>
                <Container maxWidth="xl">
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        pb={5}
                    >
                        <Typography variant="h4" sx={{fontWeight:"bold"}}>Client</Typography>
                    </Box>
                    <Box>
                        <SliderExperimental />
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default OurClient;
