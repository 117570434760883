import React, { useEffect , useState, useRef, useContext  } from 'react'
import Slider from "react-slick";
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography,
    Chip,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import img_1 from '../../assets/images/fnb/food_1.png';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import { SectionRefsContext } from '../../contex/SectionRefsProvider';

function OurServices() {
    const { section4Ref } = useContext(SectionRefsContext);
    const mockData = [1,2,3,4,5]
    const serviceData = [
        {
            name:`complete services 1`,
            subtitle:`Prolepsis 1 offers complete services in the following categories:`,
            description:`, , , ,, , Treadmill Stress Test, Spirometry,Fetal Doppler, Panoramic X-ray, Dental X-ray, Pap Smear – HPV, Emergency healthcare services, Personal and Corporate annual check-up, Clinic pharmacy, Diabetes & Nutrition clinics, Dental clinic, Full laboratory,  with the most advanced systems`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/img-20180306-wa0029-4.jpg`,
            data:[
                `Bone Densitometry  DXA`,
                `Obesity Assessment  DXA`,
                `Ultrasound Bone Densitometer`,
                `Mammography`,
                `Ultra Sound 4D`,
                `X-ray`,
                `Treadmill Stress Test`,
                `Spirometry`,
                `Fetal Doppler`,
                `Panoramic X-ray`,
                `Dental X-ray`,
                `Pap Smear – HPV`,
                `Emergency healthcare services`,
                `Personal and Corporate annual check-up`,
                `Clinic pharmacy`,
                `Diabetes & Nutrition clinics`,
                `Dental clinic`,
                `Full laboratory, with the most advanced systems`,
            ],
        },
        {
            name:`complete services 2`,
            subtitle:`Prolepsis 2 will be offering complete services in the following categories:`,
            description:`MRI 1,5T Siemens, CT Scan 128 Toshiba, Digital Mammography Siemens, Robotic Biopsy, Panoramic Digital X-ray Kodak, Dental X-ray, Diagnostic  Audi-tympanometry, Optivisor, Keratometer, Tonometer, Treadmill Stress Test, 12 Channel ECG, Spirometry, Fetal Doppler, X-ray Siemens, Bone Health Assessment DXA, Obesity by DXA, Ultrasound Bone Densitometer, Ultra Sound 4D, Ultra Sound 2D, Vein Viewer, Vaser for Liposelection, IPL  Elos  eMax , Laser 3000 NAIN, Smart PS CO2 Laser, Ambulance Service, Home Care Service`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/service-a-1.jpg`,
            data:[
                `MRI 1,5T Siemens`,
                `CT Scan 128 Toshiba`,
                `Digital Mammography Siemens`,
                `Robotic Biopsy`,
                `Panoramic Digital X-ray Kodak`,
                `Dental X-ray`,
                `Diagnostic  Audi-tympanometry`,
                `Optivisor`,
                `Keratometer`,
                `Tonometer`,
                `Treadmill Stress Test`,
                `12 Channel ECG`,
                `Spirometry`,
                `Fetal Doppler`,
                `X-ray Siemens`,
                `Bone Health Assessment DXA`,
                `Obesity by DXA`,
                `Ultrasound Bone Densitometer`,
                `Ultra Sound 4D`,
                `Ultra Sound 2D`,
                `Vein Viewer`,
                `Vaser for Liposelection`,
                `IPL  Elos  eMax `,
                `Laser 3000 NAIN`,
                `Smart PS CO2 Laser`,
                `Ambulance Service`,
                `Home Care Service`,
            ],
        },
        {
            name:`complete services 3`,
            subtitle:`Prolepsis 2 will be offering complete services in the following categories:`,
            description:`Emergency healthcare services, Personal and Corporate annual Check-up, Clinic pharmacy, Internal Medicine Clinic, Second Opinion Center, Diabetes & Nutrition Clinics, Endocrinology Clinic, Cardiology Clinic, Gastroenterology Clinic, Urology Clinic, Geriatric Center, Dialysis Center, Gynecology Clinic, Pediatric Clinic, Dental Care, Skin Center, ENT Clinic, Eye Center Clinic, Orthopedics Clinic, Osteoporosis Center, Pap Smear – HPV, Full laboratory, having the most advanced system`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/002.jpg`,
            data:[
                `Emergency healthcare services`,
                `Personal and Corporate annual Check-up`,
                `Clinic pharmacy`,
                `Internal Medicine Clinic`,
                `Second Opinion Center`,
                `Diabetes & Nutrition Clinics`,
                `Endocrinology Clinic`,
                `Cardiology Clinic`,
                `Gastroenterology Clinic`,
                `Urology Clinic`,
                `Geriatric Center`,
                `Dialysis Center`,
                `Gynecology Clinic`,
                `Pediatric Clinic`,
                `Dental Care`,
                `Skin Center`,
                `ENT Clinic`,
                `Eye Center Clinic`,
                `Orthopedics Clinic`,
                `Osteoporosis Center`,
                `Pap Smear – HPV`,
                `Full laboratory`,
                `having the most advanced system`,
            ],
        },
    ]
    return (
        <Box ref={section4Ref} sx={{backgroundColor:'#ffffff'}} py={5}>
            <Box sx={{backgroundColor:'#f5f5f5'}} py={5}>
                <Container maxWidth="xl">
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        pb={3}
                    >
                        <Typography variant="h4" sx={{fontWeight:"bold"}}>Services</Typography>
                    </Box>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        flexWrap={'wrap'}
                    >
                        {
                            serviceData.map((item)=>
                                <Box
                                    sx={{
                                        width:{ xs: '100%', sm: '100%', md: '100%', lg: `${100/3}%`, xl: `${100/3}%` },
                                    }}
                                    p={3}
                                >
                                    <Box
                                        sx={{
                                            height:'100%',
                                            width:'100%',
                                            backgroundColor:'#fff'
                                        }}
                                    >
                                        <Box
                                            display={'flex'}
                                            flexDirection={'row'}
                                            sx={{
                                                position:'relative'
                                            }}
                                        >
                                            <img 
                                                src={`${item.imageLink}`}
                                                style={{
                                                    width:'100%',
                                                    height:'100%',
                                                    objectFit:'cover',
                                                }} 
                                            />
                                            <Box
                                                sx={{
                                                    position:'absolute',
                                                    width:'100%',
                                                    left:0,
                                                    bottom:0,
                                                    backgroundColor:'rgba(0, 0, 0, 0.5)',
                                                    color:'#fff',
                                                }}
                                                p={2}
                                            >
                                                <Typography variant="h5" sx={{fontWeight:"bold",textAlign:'left'}}>{item.name}</Typography>
                                            </Box>
                                        </Box>
                                        <Box
                                            p={2}
                                        >
                                            <Box>
                                                <Typography variant="subtitle1" sx={{textAlign:'left',fontWeight:'bold'}}>
                                                {item.subtitle}
                                                </Typography>
                                            </Box>
                                            <Box
                                                display={'flex'}
                                                justifyContent={'start'}
                                                alignItems={'start'}
                                                flexWrap={'wrap'}
                                            >
                                                {
                                                    item.data&&item.data.length>0&&item.data.map((val,idx) =>
                                                        <Chip key={idx} label={`${val}`}variant="outlined" />
                                                    )
                                                }
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            )
                        }
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default OurServices;
