import React, {useEffect,useState} from 'react'
import { useTranslation } from '../../contex/TranslationContext';
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
    CardActionArea,
    Typography,
    CircularProgress,
} from '@mui/material';
import { AccessAlarm, ThreeDRotation } from '@mui/icons-material';
import XIcon from '@mui/icons-material/X';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useNavigate } from 'react-router-dom';
//css
import './Footer.css';
//media
import instagram_1 from '../../assets/images/icon/instagram.png';
import tiktok_1 from '../../assets/images/icon/tiktok.png';
//UTILS//
import {encryptData,decryptData} from '../../utils/GeneralHelper.js'
//UTILS//
//services//
import DynamicModuleManagementServices from '../../services/DynamicProsperityModuleManagement.service'
import PhoneIcon from '@mui/icons-material/Phone';
import PlaceIcon from '@mui/icons-material/Place';
import EmailIcon from '@mui/icons-material/Email';

const FooterComponent = () => {
    const { language, translateAllText } = useTranslation();
    const [originalText, setOriginalText] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
  
    const [pageData, setPageData] = useState([]);

    useEffect(() => {
        window.scrollTo(0, 0); // Scrolls to the top of the page on component mount
        // getModuleData()
    }, [language]);
  
    const getModuleData = async () => {
        setIsLoading(true)
        try{
            let params = {
              id:encryptData(1),
              orderData:'id',
              orderDirection:'ASC',
              table:'company'
            }
            const data = await DynamicModuleManagementServices.getModule(params)
            if(data.data.length>0){
                setPageData([...data.data])
            }
        } catch(error){
            alert(`There is and error`)
            console.warn(`There is and error`, error);
        } finally{
            finishFetch()
        }
    }

    const finishFetch = async () => {
        if(originalText.length==0){
            const elements = document.querySelectorAll('[data-translate-footer]');
            const texts = Array.from(elements).map((el) => el.innerText);
            setOriginalText([...texts])
        }
        if(language=='en'){
          translateProcess()
        } 
        else {
            if(originalText.length>0){
                const elements = document.querySelectorAll('[data-translate-footer]');
                elements.forEach((el, idx) => {
                  el.innerText = originalText[idx];
                });
            }
        }
        setIsLoading(false)
    };

    const translateProcess = async () => {
      const elements = document.querySelectorAll('[data-translate-footer]');
      const texts = Array.from(elements).map((el) => el.innerText);
      const newTranslations = await translateAllText(texts);
      elements.forEach((el, idx) => {
        el.innerText = newTranslations[texts[idx]];
      });
    };
    return(
        <Box 
        display={"flex"}
        sx={{
            // backgroundColor:'rgba(0, 57, 132, 1)',
            backgroundColor:'#e6e6e6',
        }}
        py={10}
        >
            <Container maxWidth="xl">
                <Box
                    p={2}
                    sx={{color:'#000'}}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Box>
                                <Box>
                                    <Typography data-translate-footer gutterBottom variant="h6" component="div" textAlign={"left"}>
                                        © Copyright 2024. PROLEPSIS.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Box>
                                    <Typography gutterBottom variant="subtitle2" component="div" textAlign={"left"}>
                                    Ruko Greenwood Golden City Bekasi Unit Nomor C2-06
                                    Villa Indah Permai RT 001 RW 002, Teluk Pucung, Kota Bekasi
                                    </Typography>
                                </Box>
                            </Box>
                            {/* <Box>
                                <Box>
                                    <Typography gutterBottom variant="h6" component="div" textAlign={"left"}>
                                    Social Media Kami:
                                    </Typography>
                                </Box>
                                <Box 
                                    display={"flex"}
                                    flexDirection={"column"}
                                    justifyContent={'center'}
                                    alignItems={'start'}
                                >
                                    <Box 
                                        display={"flex"}
                                        flexDirection={"row"}
                                        alignItems={'center'}
                                        py={1}
                                    >
                                        <img src={instagram_1} width={30} height={30} />
                                        <Typography pl={1} gutterBottom variant="subtitle2" component="div" textAlign={"left"}>
                                        prolepsis
                                        </Typography>
                                    </Box>
                                    <Box 
                                        display={"flex"}
                                        flexDirection={"row"}
                                        alignItems={'center'}
                                        py={1}
                                    >
                                        <img src={tiktok_1} width={30} height={30} />
                                        <Typography pl={1} gutterBottom variant="subtitle2" component="div" textAlign={"left"}>
                                        prolepsis
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box> */}
                            <Box>
                                <Box 
                                    display={"flex"}
                                    flexDirection={"column"}
                                    justifyContent={'center'}
                                    alignItems={'start'}
                                >
                                    <Box 
                                        display={"flex"}
                                        flexDirection={"row"}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        py={1}
                                    >
                                        <PhoneIcon sx={{fontSize: 40}} />
                                        <Typography pl={1} gutterBottom variant="subtitle2" component="div" textAlign={"left"}>
                                        021 89257365
                                        </Typography>
                                    </Box>
                                    <Box 
                                        display={"flex"}
                                        flexDirection={"row"}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        py={1}
                                    >
                                        <EmailIcon sx={{fontSize: 40}} />
                                        <Typography pl={1} gutterBottom variant="subtitle2" component="div" textAlign={"left"}>
                                        infopmc@prolepsis.co.id
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            {/* <Box>
                                <Box>
                                    <Typography gutterBottom variant="h6" component="div" textAlign={"left"}>
                                    Social Media Kami:
                                    </Typography>
                                </Box>
                                <Box 
                                    display={"flex"}
                                    flexDirection={"column"}
                                    justifyContent={'center'}
                                    alignItems={'start'}
                                >
                                    <Box 
                                        display={"flex"}
                                        flexDirection={"row"}
                                        alignItems={'center'}
                                        py={1}
                                    >
                                        <img src={instagram_1} width={30} height={30} />
                                        <Typography pl={1} gutterBottom variant="subtitle2" component="div" textAlign={"left"}>
                                        prolepsis
                                        </Typography>
                                    </Box>
                                    <Box 
                                        display={"flex"}
                                        flexDirection={"row"}
                                        alignItems={'center'}
                                        py={1}
                                    >
                                        <img src={tiktok_1} width={30} height={30} />
                                        <Typography pl={1} gutterBottom variant="subtitle2" component="div" textAlign={"left"}>
                                        prolepsis
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box> */}
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <Box
                                display={"flex"}
                                justifyContent={'center'}
                                alignItems={'center'}
                                sx={{
                                    width:'100%',
                                    height:'100%',
                                }} 
                            >
                                <img 
                                    src={`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/cropped-logo-prolepsis.png`}
                                    style={{
                                        width:'100%',
                                        // height:'100%',
                                        objectFit:'cover',
                                    }} 
                                />
                            </Box>
                        </Grid>
                        {/* <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                            <Box>
                                <Box>
                                    <Typography gutterBottom variant="h6" component="div" textAlign={"left"}>
                                        Get In Touch
                                    </Typography>
                                </Box>
                                <Box
                                    display={"flex"}
                                    flexDirection={"row"}
                                    py={1}
                                >
                                    <Box
                                        display={"flex"}
                                        alignItems={'center'}
                                        sx={{height:'100%'}}
                                        mr={3}
                                    >
                                        <PhoneIcon sx={{fontSize: 40}} />
                                    </Box>
                                    <Box>
                                        <Box>
                                            <Typography data-translate-contact-us variant="subtitle1" color="text.secondary" textAlign={"left"}>
                                                Phone
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="subtitle1" color="text.secondary" textAlign={"left"}>
                                                021 89257365
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid> */}
                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}

export default FooterComponent