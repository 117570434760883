import React, { useEffect , useState, useRef, useContext } from 'react'
import Slider from "react-slick";
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography
} from '@mui/material';
// Images
import bg_2 from '../../assets/images/bg_2.jpeg';
import { SectionRefsContext } from '../../contex/SectionRefsProvider';

function OurMission() {
    const { section1Ref } = useContext(SectionRefsContext);
    const serviceData = [
        {
            type:`text`,
            imageLink:``,
            description:`Personalized pre-counseling and medical exam.`,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/img-20180306-wa0029-4.jpg`,
            description:``,
        },
        {
            type:`text`,
            imageLink:``,
            description:`Blood tests, vaccine history and profile completion (i.e. dietary habits, physical activity).`,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/service-c.jpg`,
            description:``,
        },
        {
            type:`text`,
            imageLink:``,
            description:`Performance analysis of the heart, and cardiopulmonary system.`,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/service-a-1.jpg`,
            description:``,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/service-d.jpg`,
            description:``,
        },
        {
            type:`text`,
            imageLink:``,
            description:`Standard check-ups and tailored examinations depending on patient’s needs.`,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/img-20180306-wa0024-1.jpg`,
            description:``,
        },
        {
            type:`text`,
            imageLink:``,
            description:`Specialized imaging technologies, as necessary.`,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/002.jpg`,
            description:``,
        },
        {
            type:`text`,
            imageLink:``,
            description:`Assessment and in-depth explanation of results by our medical experts.`,
        },
    ]
  return (
    <Box ref={section1Ref} sx={{backgroundColor:'#ffffff'}} py={5}>
      <Box sx={{backgroundColor:'#f5f5f5'}}>
        <Container maxWidth="xl">
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                flexWrap={'wrap'}
            >
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    sx={{
                        width:{ xs: '100%', sm: '100%', md: '100%', lg: `40%`, xl: `40%` }
                    }}
                    p={3}
                >
                    <Box>
                        <Box>
                            <Typography variant="h4" sx={{fontWeight:"bold"}}>About</Typography>
                        </Box>
                        <Box sx={{textAlign:'justify'}}>
                            <Typography variant="subtitle1">
                            PROLEPSIS introduces an innovative approach to healthcare. At Prolepsis Medical Center, we prioritize our clients' well-being, offering comprehensive healthcare services, insights, and support to help you maintain optimal health.
                            </Typography>
                            <Typography variant="subtitle1">
                            Good health involves self-care, balanced nutrition, regular exercise, and smart lifestyle choices. It also requires routine check-ups to detect potential health issues early, before symptoms appear.
                            </Typography>
                            <Typography variant="subtitle1">
                            With a strong emphasis on prevention and an unwavering commitment to care and reliability, we guide you through every step of the process—from initial assessment to detailed results reporting—delivering high-quality medical services that enhance your healthcare experience.
                            </Typography>
                            <Typography variant="subtitle1">
                            Our mission is to be your preferred healthcare partner. To achieve this, we have assembled a dedicated medical team, invested in advanced technological equipment, and adhered to strict European compliance and inspection standards.
                            </Typography>
                            <Typography variant="subtitle1">
                            “We strive to provide the most accessible, friendly, and dependable healthcare service in Indonesia.”
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width:{ xs: '100%', sm: '100%', md: '100%', lg: `60%`, xl: `60%` }
                    }}
                    p={3}
                >
                    <Box>
                        <img 
                            src={`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/service-a-1.jpg?w=1024`} 
                            style={{
                                width:'100%',
                                height:'100%',
                                objectFit:'cover',
                            }} 
                        />
                    </Box>
                </Box>
            </Box>
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                flexWrap={'wrap'}
            >
                <Box
                    sx={{
                        width:{ xs: '100%', sm: '100%', md: '100%', lg: `60%`, xl: `60%` }
                    }}
                    p={3}
                >
                    <Box>
                        <img 
                            src={`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/service-c.jpg`}
                            style={{
                                width:'100%',
                                height:'100%',
                                objectFit:'cover',
                            }} 
                        />
                    </Box>
                </Box>
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    sx={{
                        width:{ xs: '100%', sm: '100%', md: '100%', lg: `40%`, xl: `40%` }
                    }}
                    p={3}
                >
                    <Box>
                        <Box>
                            <Typography variant="h4" sx={{fontWeight:"bold"}}>Our Mission</Typography>
                        </Box>
                        <Box sx={{textAlign:'justify'}}>
                            <Typography variant="subtitle1">
                            Our mission is to raise awareness about health prevention and establish the highest standards for quality health services in Indonesia. We aim to provide our clients with comfortable, efficient, and accurate care.
                            </Typography>
                            <Typography variant="subtitle1">
                            By leveraging the latest diagnostic technologies and employing highly qualified professionals who share our commitment, we strive to be the premier medical center in Indonesia.
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                pb={5}
            >
                <Typography variant="h4" sx={{fontWeight:"bold"}}>What We Do</Typography>
            </Box>
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                flexWrap={'wrap'}
            >
                {
                    serviceData.map((item,index)=>
                        <Box
                            sx={{
                                width:{ xs: '100%', sm: '100%', md: '100%', lg: `${100/6}%`, xl: `${100/6}%` }
                            }}
                            // p={3}
                        >
                            {
                                item.type=='image'
                                ?
                                <Box
                                    key={index}
                                    sx={{
                                        aspectRatio:1/1,
                                        width:'100%',
                                        // backgroundColor:'rgba(0, 57, 132, 1)',
                                        // backgroundColor:'#EDEDED',
                                    }} 
                                >
                                    <img 
                                        src={`${item.imageLink}`}
                                        style={{
                                            width:'100%',
                                            height:'100%',
                                            objectFit:'cover',
                                        }} 
                                    />
                                </Box>
                                :
                                <Box
                                    key={index}
                                    sx={{
                                        aspectRatio:1/1,
                                        width:'100%',
                                        backgroundColor:'#EDEDED',
                                        // backgroundColor:'rgba(0, 57, 132, 1)',
                                        // color:'#fff',
                                    }} 
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    p={3}
                                >
                                    <Typography variant="subtitle1" sx={{textAlign:'center',fontWeight:'bold'}}>
                                    {item.description}
                                    </Typography>
                                </Box>
                            }
                        </Box>
                    )
                }
            </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default OurMission;
