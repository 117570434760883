import React, { useEffect , useState, useRef, useContext  } from 'react'
import Slider from "react-slick";
import {
    Container,
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
    Typography
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import img_1 from '../../assets/images/fnb/food_1.png';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined';
import { SectionRefsContext } from '../../contex/SectionRefsProvider';

function WhatWeDo() {
    const { section3Ref } = useContext(SectionRefsContext);
    const mockData = [1,2,3,4,5,6,7,8,9,10,11,12]
    const serviceData = [
        {
            type:`text`,
            imageLink:``,
            description:`Personalized pre-counseling and medical exam.`,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/img-20180306-wa0029-4.jpg`,
            description:``,
        },
        {
            type:`text`,
            imageLink:``,
            description:`Blood tests, vaccine history and profile completion (i.e. dietary habits, physical activity).`,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/service-c.jpg`,
            description:``,
        },
        {
            type:`text`,
            imageLink:``,
            description:`Performance analysis of the heart, and cardiopulmonary system.`,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/service-a-1.jpg`,
            description:``,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/service-d.jpg`,
            description:``,
        },
        {
            type:`text`,
            imageLink:``,
            description:`Standard check-ups and tailored examinations depending on patient’s needs.`,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/img-20180306-wa0024-1.jpg`,
            description:``,
        },
        {
            type:`text`,
            imageLink:``,
            description:`Specialized imaging technologies, as necessary.`,
        },
        {
            type:`image`,
            imageLink:`https://prolepsismedicalcentershealthcare.wordpress.com/wp-content/uploads/2021/02/002.jpg`,
            description:``,
        },
        {
            type:`text`,
            imageLink:``,
            description:`Assessment and in-depth explanation of results by our medical experts.`,
        },
    ]
    return (
        <Box ref={section3Ref} sx={{backgroundColor:'#ffffff'}} py={5}>
            <Box sx={{backgroundColor:'#f5f5f5'}} py={5}>
                <Container maxWidth="xl">
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        pb={5}
                    >
                        <Typography variant="h4" sx={{fontWeight:"bold"}}>What We Do</Typography>
                    </Box>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        flexWrap={'wrap'}
                    >
                        {
                            serviceData.map((item,index)=>
                                <Box
                                    sx={{
                                        width:{ xs: '100%', sm: '100%', md: '100%', lg: `${100/6}%`, xl: `${100/6}%` }
                                    }}
                                    // p={3}
                                >
                                    {
                                        item.type=='image'
                                        ?
                                        <Box
                                            key={index}
                                            sx={{
                                                aspectRatio:1/1,
                                                width:'100%',
                                                // backgroundColor:'rgba(0, 57, 132, 1)',
                                                // backgroundColor:'#EDEDED',
                                            }} 
                                        >
                                            <img 
                                                src={`${item.imageLink}`}
                                                style={{
                                                    width:'100%',
                                                    height:'100%',
                                                    objectFit:'cover',
                                                }} 
                                            />
                                        </Box>
                                        :
                                        <Box
                                            key={index}
                                            sx={{
                                                aspectRatio:1/1,
                                                width:'100%',
                                                backgroundColor:'#EDEDED',
                                                // backgroundColor:'rgba(0, 57, 132, 1)',
                                                // color:'#fff',
                                            }} 
                                            display={'flex'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            p={3}
                                        >
                                            <Typography variant="subtitle1" sx={{textAlign:'center',fontWeight:'bold'}}>
                                            {item.description}
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                            )
                        }
                    </Box>
                </Container>
            </Box>
        </Box>
    );
}

export default WhatWeDo;
